<template>
    <div class="auditnoOperatingDivisionsReview">
        <jy-dialog type="form"
            :title="title"
            :visible.sync="dialogVisible"
            custom-class="dialog_form"
            :width="global.dialogWidth"
        >
            <div>
                <el-form
                    :model="diaform"
                    ref="dialogForm"
                    :rules="rules"
                    :disabled="isCheck"
                    :label-width="formLabelWidth"
                >
                    <el-form-item label="审核结果:" prop="auditState" :label-width="formLabelWidth">
                        <el-radio v-model="diaform.auditState" label="2">通过</el-radio>
                        <el-radio v-model="diaform.auditState" label="1">不通过</el-radio>
                    </el-form-item>
                    <el-form-item label="备注:" prop="auditRemark">
                        <el-input
                            v-model="diaform.auditRemark"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入内容"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="!isCheck">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save('dialogForm')">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
  data() {
    return {
      type: '',
      isCheck: false,
      title: '',
      props: {
        children: 'children',
        label: 'text',
        value: 'id',
        // multiple: true,
        checkStrictly: true
      },
      diaform: {
        auditTime: '',
        auditUserId: '',
        auditState: '2',
        auditRemark: '',
        ids: []
      },
      selectData: '',
      rules: {
        showPlate: [
          {
            required: true,
            message: '请选取车牌',
            trigger: 'blur'
          }
        ]
      },
      dialogVisible: false,
      formLabelWidth: '120px'
    }
  },
  components: {},
  activated() {},
  created() {},
  watch: {
    type: function(val) {
      let t = ''
      let isCheck = false
      switch (val) {
        case 'batch':
          t = '非营运批量审核'
          break
        case 'single':
          t = '非营运审核'
          break
        case 'view':
          t = '查看非营运审核'
          isCheck = true
          break
        default:
          t = '非营运批量审核'
      }
      this.isCheck = isCheck
      this.title = t
    },
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('dialogForm')
      }
    }
  },
  methods: {
    init(option, type) {
      this.type = type ? type : 'batch'
      this.dialogVisible = true
      if (option) {
        this.$nextTick(() => {
          this.selectData = JSON.parse(JSON.stringify(option))
          console.log(option)
        })
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let option = {}
          if (this.type == 'batch') {
            let ids = []
            this.selectData.forEach(item => {
              ids.push(item.waybillId)
            })
            option = {
              auditState: this.diaform.auditState,
              auditRemark: this.diaform.auditRemark,
              ids: ids
            }
          } else {
            option = {
              //   auditTime: new Date().Format('yyyy-MM-dd hh:mm:ss'),
              //   auditUserId: this.selectData.auditUserId,
              auditState: this.diaform.auditState,
              auditRemark: this.diaform.auditRemark,
              ids: [this.selectData.waybillId]
            }
          }
          console.log(option)
          let url = '/waybillNonOperateSettle/batchAudit'
          this.$http.post(url, option).then(res => {
            this.$message({
              type: 'success',
              message: res.msg
            })
            this.dialogVisible = false
            this.$emit('updateList')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>